export default [
    {
        path: '/',
        name: 'base',
        component: () => import('@/components/layout/main.vue'),
        children: [
            // 首页
            {
                path: '/',
                name: 'index',
                component: () =>import('@/components/index.vue')
            },
            {
                path: '/member/index',
                name: 'member.index',
                component: () =>import('@/components/member/user.vue')
            },
            {
                path: '/member/group',
                name: 'member.group',
                component: () =>import('@/components/member/group.vue')
            },
            {
                path: '/package/index',
                name: 'package.index',
                component:() => import('@/components/package/index.vue')
            }
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () =>import('@/components/auth/login.vue'),
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () =>import('@/components/auth/logout.vue'),
    },
]
